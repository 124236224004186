<template>
  <div class="layout-container">
    <Header></Header>
    <Container></Container>
  </div>
</template>
<script>
import Header from "@/components/header/index.vue";
import Container from "@/components/container/index.vue";
export default {
  name: "layout",
  data() {
    return {};
  },
  components: {
    Container,
    Header,
  },
};
</script>
<style lang="less" scoped>
.layout-container {
  // display: flex;
  min-height: 100vh;
}
</style>
