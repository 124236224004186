<template>
  <div class="header-container">
    <div class="header-left">
      <p>湖北风吟通信科技有限公司</p>
      <!-- <i :class="isCollapse === true ? 'el-icon-s-fold': 'el-icon-s-unfold'" @click="fold"></i> -->
      <p>{{ userInfo.level_name }}</p>
    </div>
    <div class="header-right">
      <p class="name">欢迎您，{{ userInfo.name }}</p>
      <el-dropdown>
        <span class="el-dropdown-link">
          <img src="../../assets/0.png" alt="" /><i
            class="el-icon-arrow-down el-icon--right"
          ></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="dialogVisible = true"
            >修改密码</el-dropdown-item
          >
          <el-dropdown-item @click.native="signOut" class="signOut"
            >退出登录</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="60%">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="旧密码" prop="old_pwd">
          <el-input
            v-model="ruleForm.old_pwd"
            placeholder="请输入旧密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="new_pwd">
          <el-input
            v-model="ruleForm.new_pwd"
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user: {}, // 当前用户信息
      dialogVisible: false,
      ruleForm: {
        old_pwd: "",
        new_pwd: "",
        user_id: JSON.parse(window.localStorage.getItem("user")).id,
      },
      rules: {
        old_pwd: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
        new_pwd: [{ required: true, message: "请输入新密码", trigger: "blur" }],
      },
    };
  },
  created() {},
  computed: {
    userInfo() {
      return JSON.parse(window.localStorage.getItem("user"));
    },
  },
  methods: {
    // 退出功能
    signOut() {
      window.localStorage.removeItem("user");
      this.$router.push({ path: "/login" });
    },
    // 修改密码
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let response = await this.$request.post("/updatePwd", this.ruleForm);
          console.log(response);
          if (response.data.success) {
            this.$message.success("修改成功");
            this.signOut();
          }
        } else {
          this.$message.error("请输入必填项");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.header-container {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  background-color: #1890ff;
  color: #fff;
  .header-left,
  .header-right {
    flex: 1;
    display: flex;
    align-items: center;
    i {
      cursor: pointer;
    }
    p {
      margin-left: 10px;
    }
  }
  .header-right {
    justify-content: end;
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      cursor: pointer;
    }
    i {
      margin-left: 10px;
    }
  }
  .signOut {
    cursor: pointer;
  }
}
.el-dropdown {
  margin-left: 20px;
  display: flex;
}
</style>
