<template>
  <div class="content">
    <Sidebar></Sidebar>
    <div class="container">
      <el-card class="box-card">
        <router-view />
      </el-card>
      <el-footer>
        <span>做接口使用 无实质内容</span>
        <a href="https://beian.miit.gov.cn">鄂ICP备2022001233号-1</a>
      </el-footer>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/components/sidebar/index.vue";
import Header from "@/components/header/index.vue";
export default {
  data() {
    return {};
  },
  components: {
    Header,
    Sidebar,
  },
};
</script>
<style lang="less" scoped>
.content {
  display: flex;
  width: 100%;
  background-color: #e9eef3;

  .container {
    padding: 10px 10px;
    width: inherit;
  }
}

.box-card {
  min-height: 100vh;
}

/deep/.el-footer {
  text-align: center;
  line-height: 60px;

  a {
    color: #adadad;
  }
}
</style>
