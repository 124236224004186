<template>
  <div class="sidebar-container">
    <el-menu
      :default-active="defaultPath"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      router
    >
      <el-menu-item index="/addOrder" v-if="userInfo.level == 3">
        <i class="el-icon-s-order"></i>
        <span slot="title">新增工单</span>
      </el-menu-item>
      <el-menu-item index="/order">
        <i class="el-icon-s-order"></i>
        <span slot="title">工单列表</span>
      </el-menu-item>
      <el-menu-item index="/outOrder" v-if="userInfo.level == 1">
        <i class="el-icon-printer"></i>
        <span slot="title">导出工单</span>
      </el-menu-item>
      <el-menu-item index="/progress">
        <i class="el-icon-search"></i>
        <span slot="title">查看进度</span>
      </el-menu-item>
      <el-menu-item index="/adduser" v-if="userInfo.level == 1">
        <i class="el-icon-s-custom"></i>
        <span slot="title">新增用户</span>
      </el-menu-item>
      <el-menu-item index="/user" v-if="userInfo.level == 1">
        <i class="el-icon-s-custom"></i>
        <span slot="title">用户列表</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>
<script>
export default {
  data() {
    return {
      defaultPath: "",
      userInfo: JSON.parse(window.localStorage.getItem("user")),
    };
  },
  computed: {
    // 从vuex 中获取 isCollapse 用来控制侧边栏的现实与隐藏
    // isCollapse () {
    //   return this.$store.state.isCollapse
    // }
  },
  mounted() {
    this.defaultPath = this.$route.path;
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>
<style lang="less" scoped>
.sidebar-container {
  .el-menu-vertical-demo {
    height: 100%;
  }
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  min-height: 400px;
}
</style>
